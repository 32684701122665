<template>
  <div class="show-root-container">
    <div class="show-title-container">
      <img :src="icons.show_title" class="show-title-img" />
    </div>
    <div class="show-title">线上展厅</div>
    <water-fall class="waterfall-root-container" align="center" :line-gap="350" :watch="opus_list" ref="waterfall">
      <water-fall-slot v-for="(item, index) in opus_list" :width="item.containerWidth" :height="item.containerHeight"
        :order="index" :key="index" style="overflow: hidden" move-class="item-move">
        <div class="waterfall-item-container" @click="showDesignModal(item)">
          <div class="div-background" :style="{
              width: item.opus_width + 'px',
              height: item.opus_height + 'px',
              backgroundImage: `url(${item.opus_img_url}/640)`,
            }"></div>
          <div class="waterfall-item-comment-container" :style="{
              width: item.opus_width + 'px',
              height: '80px',
            }">
            <img :src="icons.triangle" style="width: 15px; height: 15px; transform: rotate(90deg)" />
            <div class="waterfall-item-comment-avatar div-background" :style="{
                backgroundImage: `url(${item.comment_img_url})`,
              }"></div>
            <div style="flex: 1" class="waterfall-item-comment-content multi-text">{{ item.comment_content }}</div>
          </div>
        </div>
      </water-fall-slot>
    </water-fall>

    <design-modal ref="designModal" />
  </div>
</template>

<script>
import Waterfall from "vue-waterfall/lib/waterfall";
import WaterfallSlot from "vue-waterfall/lib/waterfall-slot";
import * as eventHelper from "../../utils/eventHelper";
import { mapActions, mapState, mapGetters } from "vuex";
import DesignModalVue from "../Index/Modal/DesignModal.vue";
import * as type from "../../store/type";
export default {
  components: {
    "water-fall": Waterfall,
    "water-fall-slot": WaterfallSlot,
    "design-modal": DesignModalVue,
  },
  data() {
    return {
      icons: this.$root.icons,
      fallList: [],
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.$store.commit(type.CLEAR_OPUS_LIST);

    this.getList();
  },
  computed: {
    // ...mapGetters({
    //   partOfOpusList: "partOfOpusList",
    // }),
    ...mapState({
      opus_list: (state) => state.opus.opus_list,
      opus_page_index: (state) => state.opus.opus_page_index,
      opus_page_size: (state) => state.opus.opus_page_size,
      opus_total_count: (state) => state.opus.opus_total_count,
    }),
  },
  methods: {
    ...mapActions({
      opusGetListAction: "opusGetList",
    }),
    getList() {
      this.opusGetListAction({
        page_size: this.opus_page_size,
        page_index: this.opus_page_index,
      })
        .then((res) => {})
        .catch((error) => {
          this.$message.error(error);
        });
    },
    showDesignModal(item) {
      this.$refs.designModal.open(item);
    },
  },
};
</script>

<style scoped>
.show-root-container {
  display: flex;
  flex-direction: column;
  padding: 0px 125px;
}
.show-title-container {
  width: 100%;
  height: 151px;
  margin-top: 70px;
  padding-bottom: 5px;
  border-bottom: 1px solid #282828;
}
.show-title-img {
  height: 100%;
}
.show-title {
  margin-top: 10px;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  color: #282828;
  letter-spacing: 0;
}
.waterfall-root-container {
  width: 100%;
  margin-top: 45px;
  margin-bottom: 150px;
}
.waterfall-item-container {
  border: 5px solid #062c3e;
  position: absolute;
  top: 25px;
  left: 25px;
  right: 25px;
  bottom: 25px;
  background: #062c3e;
  cursor: pointer;
}
.waterfall-item-comment-container {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  overflow: hidden;
}
.waterfall-item-comment-avatar {
  width: 45px;
  height: 45px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 20px;
}
.waterfall-item-comment-content {
  font-size: 12px;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}
</style>