var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":797,"footer":null,"closable":false,"bodyStyle":{
    margin: 0,
    padding: 0,
  }},model:{value:(_vm.isShowModal),callback:function ($$v) {_vm.isShowModal=$$v},expression:"isShowModal"}},[_c('div',{staticClass:"design-modal-root-container"},[_c('img',{class:[
        _vm.widthPic
          ? 'design-pic-height-container'
          : 'design-pic-width-container' ],attrs:{"src":_vm.designDetail.opus_img_url}}),_c('div',{staticClass:"design-comment-container div-background",style:({
        backgroundImage: ("url(" + (_vm.icons.index_comment_background) + ")"),
      })},[_c('div',{staticClass:"design-avatar-container"},[_c('img',{staticClass:"design-avatar-widget",attrs:{"src":_vm.icons.index_comment_avatar_widget}}),_c('div',{staticClass:"design-avatar div-background",style:({
            backgroundImage: ("url(" + (_vm.designDetail.comment_img_url) + ")"),
          })})]),_c('div',{staticClass:"design-info-container"},[_c('div',{staticClass:"design-name single-text"},[_vm._v(" "+_vm._s(_vm.designDetail.comment_name)+" ")]),_c('div',{staticClass:"design-comment multi-text"},[_vm._v(_vm._s(_vm.designDetail.comment_content))])])]),_c('div',{staticClass:"design-close",on:{"click":_vm.close}},[_vm._v("X")])])])}
var staticRenderFns = []

export { render, staticRenderFns }