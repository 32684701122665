<template>
  <a-modal v-model="isShowModal" :width="797" :footer="null" :closable="false" :bodyStyle="{
      margin: 0,
      padding: 0,
    }">
    <div class="design-modal-root-container">
      <img :src="designDetail.opus_img_url" :class="[
          widthPic
            ? 'design-pic-height-container'
            : 'design-pic-width-container',
        ]" />
      <!-- <div
        class="design-pic-container div-background"
        :style="{
          backgroundImage: `url(${designDetail.opus_img_url})`,
        }"
      ></div> -->
      <div class="design-comment-container div-background" :style="{
          backgroundImage: `url(${icons.index_comment_background})`,
        }">
        <div class="design-avatar-container">
          <img class="design-avatar-widget" :src="icons.index_comment_avatar_widget" />
          <div class="design-avatar div-background" :style="{
              backgroundImage: `url(${designDetail.comment_img_url})`,
            }"></div>
        </div>

        <div class="design-info-container">
          <div class="design-name single-text">
            {{ designDetail.comment_name }}
          </div>
          <div class="design-comment multi-text">{{ designDetail.comment_content }}</div>
        </div>
      </div>

      <div class="design-close" @click="close">X</div>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      icons: this.$root.icons,
      isShowModal: false,
      widthPic: false,
      designDetail: {
        img_url: "",
        comment_img_url: "",
        comment_name: "",
        comment_content: "",
      },
    };
  },
  methods: {
    open(dataSource) {
      this.designDetail = dataSource;
      this.widthPic = dataSource.opus_width >= dataSource.opus_height;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.design-modal-root-container {
  display: flex;
  flex-direction: column;
}
.design-pic-width-container {
  width: 500px;
  margin: 0 auto;
  background-size: auto !important;
}
.design-pic-height-container {
  height: 500px;
  max-width: 797px;
  background-size: auto !important;
}
.design-comment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 797px;
  height: 181px;
  padding-right: 200px;
  white-space: pre-wrap;
}
.design-avatar-container {
  position: relative;
  min-width: 120px;
  height: 80px;
  margin-left: 48px;
}
.design-avatar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  border: 3px solid #ffffff;
  border-radius: 50%;
}
.design-avatar-widget {
  position: absolute;
  top: -15px;
  right: 20px;
  width: 50px;
}
.design-info-container {
  margin-left: 42px;
  width: calc(797px - 120px - 48px - 42px);
}
.design-name {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  width: 100%;
}
.design-comment {
  margin-top: 12px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  -webkit-line-clamp: 5;
}
.design-close {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 50px;
  height: 50px;
  font-size: 24px;
  border-radius: 50%;
  background-color: white;
  margin-top: 45px;
  cursor: pointer;
}
</style>