var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"show-root-container"},[_c('div',{staticClass:"show-title-container"},[_c('img',{staticClass:"show-title-img",attrs:{"src":_vm.icons.show_title}})]),_c('div',{staticClass:"show-title"},[_vm._v("线上展厅")]),_c('water-fall',{ref:"waterfall",staticClass:"waterfall-root-container",attrs:{"align":"center","line-gap":350,"watch":_vm.opus_list}},_vm._l((_vm.opus_list),function(item,index){return _c('water-fall-slot',{key:index,staticStyle:{"overflow":"hidden"},attrs:{"width":item.containerWidth,"height":item.containerHeight,"order":index,"move-class":"item-move"}},[_c('div',{staticClass:"waterfall-item-container",on:{"click":function($event){return _vm.showDesignModal(item)}}},[_c('div',{staticClass:"div-background",style:({
            width: item.opus_width + 'px',
            height: item.opus_height + 'px',
            backgroundImage: ("url(" + (item.opus_img_url) + "/640)"),
          })}),_c('div',{staticClass:"waterfall-item-comment-container",style:({
            width: item.opus_width + 'px',
            height: '80px',
          })},[_c('img',{staticStyle:{"width":"15px","height":"15px","transform":"rotate(90deg)"},attrs:{"src":_vm.icons.triangle}}),_c('div',{staticClass:"waterfall-item-comment-avatar div-background",style:({
              backgroundImage: ("url(" + (item.comment_img_url) + ")"),
            })}),_c('div',{staticClass:"waterfall-item-comment-content multi-text",staticStyle:{"flex":"1"}},[_vm._v(_vm._s(item.comment_content))])])])])}),1),_c('design-modal',{ref:"designModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }